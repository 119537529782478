import React, { FC, PropsWithChildren, createContext, useMemo } from "react";

import useConfig from "@providers/configProvider/useConfig";

import useUserState from "./useUserState";

const UserContext = createContext<any | undefined>(undefined);

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { config } = useConfig();

  const {
    isLoading,
    isLoadingImages,
    getUserByBarcode,
    user,
    activeBarcode,
    saveUser,
    resetUser,
    getUserPhotosByBarcode,
    photos,
    shareImageViaEmail,
    saveSiverScreenBg,
    selectedPhotos,
    selectPhoto,
    selectSinglePhoto,
    selectAllPhotos,
    clearSelectedPhotos,
    isInterviewing,
    checkIsShouldSurvey,
    handleSurveyClose,
    handleSurveySubmit,
  } = useUserState(config?.isKiosk, config?.API_URI);

  const value = useMemo(
    () => ({
      isLoading,
      isLoadingImages,
      getUserByBarcode,
      user,
      saveUser,
      resetUser,
      getUserPhotosByBarcode,
      photos,
      shareImageViaEmail,
      saveSiverScreenBg,
      activeBarcode,
      selectedPhotos,
      selectPhoto,
      selectSinglePhoto,
      selectAllPhotos,
      clearSelectedPhotos,
      isInterviewing,
      checkIsShouldSurvey,
      handleSurveyClose,
      handleSurveySubmit,
    }),
    [
      isLoading,
      isLoadingImages,
      user,
      activeBarcode,
      photos,
      selectedPhotos,
      isInterviewing,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserProvider, UserContext };
