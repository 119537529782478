import React, { FC } from "react";
import { Box, Typography } from "@mui/material";

import {
  loaderStyles,
  loaderWrapperStyles,
  loaderBluredStyles,
  loaderFullHeightStyles,
  loaderFullWidthStyles,
  loaderAbsoluteStyles,
  loaderFixedStyles,
  loaderContentStyles,
  loaderCandyStyles,
  loaderTitleStyles,
} from "./loader.styles";

interface ILoaderProps {
  isAbsolute?: boolean;
  isHeightFull?: boolean;
  isWidthFull?: boolean;
  blurBg?: boolean;
  isCandy?: boolean;
}

const Loader: FC<ILoaderProps> = ({
  isAbsolute = false,
  isHeightFull = false,
  isWidthFull = false,
  blurBg = false,
  isCandy = false,
}) => (
  <Box
    sx={[
      loaderWrapperStyles,
      blurBg && loaderBluredStyles,
      isHeightFull && loaderFullHeightStyles,
      isWidthFull && loaderFullWidthStyles,
      isAbsolute && loaderAbsoluteStyles,
      isCandy && loaderFixedStyles,
    ]}
  >
    {isCandy ? (
      <Box sx={loaderContentStyles}>
        <Typography sx={loaderTitleStyles}>Please wait...</Typography>
        <Box sx={loaderCandyStyles} />
      </Box>
    ) : (
      <Box sx={loaderStyles} />
    )}
  </Box>
);

export default Loader;
