import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { ConfigProvider } from "./providers/configProvider/ConfigProvider";
import ConfigChecker from "./ConfigChecker";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <ConfigProvider>
      <ConfigChecker />
    </ConfigProvider>
  </QueryClientProvider>
);

export default App;
