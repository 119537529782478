import { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import axios from "axios";

import api from "@api/index";
import { IResponseDto } from "@interfaces/rest/response.dto";
import { IGuestDto, IGuestSaveDto } from "@interfaces/rest/guestData.dto";
import { IMediaDto } from "@interfaces/rest/media.dto";
import { ISurveyAnswer } from "@pages/Survey/survey.types";
import { QueryKeyEnum } from "@interfaces/enums/queryKeys.enum";

import { IUserState } from "./user.types";

const useUserState = (
  isKiosk?: boolean,
  expressionsAPI?: string
): IUserState => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [user, setUser] = useState<IGuestDto | null>(null);
  const [activeBarcode, setActiveBarcode] = useState("");
  const [photos, setPhotos] = useState<IMediaDto[] | null>(null);
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
  const [isInterviewing, setIsInterviewing] = useState(false);

  const getUserData = async (barcode: string) => {
    const response = await axios.get(api.guestInfo(barcode));
    return response.data;
  };

  const { refetch: refetchUserData, isFetching: isUserFetching } = useQuery(
    [QueryKeyEnum.getUserData, activeBarcode],
    () => getUserData(activeBarcode),
    {
      enabled: false,
      cacheTime: 0,
      onSuccess: (response) => {
        if (!response.state && !response.response) {
          navigate(`/login?ticketBarcode=${activeBarcode}`);
          return;
        }
        setUser(response.response);
        getUserPhotosByBarcode(activeBarcode);
        if (!isKiosk) {
          localStorage.setItem("barcode", activeBarcode);
        }
        navigate("/photos");
      },
    }
  );

  const saveUserMutation = useMutation(
    (data: {
      ticketBarcodes: string;
      email: string;
      zipCode: string;
      captureNewsYN: boolean;
    }) =>
      axios
        .post<IResponseDto<IGuestSaveDto>>(api.saveGuest, {
          data: {
            email: data.email,
            zipCode: data.zipCode,
            captureNewsYN: data.captureNewsYN,
          },
          ticketBarcodes: data.ticketBarcodes,
          survey: {},
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        }),
    {
      onSuccess: (response) => {
        if (response.data.response?.guestId) {
          refetchUserData();
        }
      },
      onError: (error) => {
        console.error("Save user failed:", error);
      },
    }
  );

  const getUserPhotosByBarcode = async (barcode: string): Promise<void> => {
    setIsLoadingImages(true);
    try {
      const response = await axios.get<IResponseDto<IMediaDto[]>>(
        api.guestPhotos(barcode)
      );
      if (response.data.response?.length) {
        setPhotos(response.data.response);
      }
    } catch (error) {
      console.error("Error getting photos by barcode:", error);
    } finally {
      setIsLoadingImages(false);
    }
  };

  const handleSurveySubmit = async (
    answersList: ISurveyAnswer
  ): Promise<void> => {
    try {
      const questionIds = Object.keys(answersList);
      const answersDto = questionIds.map((questionId) => ({
        questionId,
        answer: answersList[questionId],
      }));

      await axios.post(api.postSurveyAnswer, {
        answers: answersDto,
        guestId: user?.guestId,
        ticketBarcode: activeBarcode,
      });

      setIsInterviewing(false);
      setUser((prevUser) =>
        prevUser
          ? {
              ...prevUser,
              guestData: {
                ...prevUser.guestData,
                passedSurvey: true,
              },
            }
          : null
      );
    } catch (error) {
      console.error("Error submiting survey:", error);
    }
  };

  const shareImageViaEmail = async (
    id: string | string[],
    email: string
  ): Promise<string> => {
    try {
      if (Array.isArray(id)) {
        const requests = id.map((mediaId) =>
          axios.post(expressionsAPI + api.mediaShare(mediaId), {
            emailsList: [email],
          })
        );
        await Promise.allSettled(requests);
        setSelectedPhotos([]);
        return "";
      }

      const response = await axios.post(api.mediaShare(id), {
        emailsList: [email],
      });
      return response.data.state ? "" : response.data.messages.join(", ");
    } catch (error: any) {
      return error?.message || "Unexpected error";
    }
  };

  const saveSiverScreenBg = async (
    mediaId: string,
    backgroundId: string
  ): Promise<boolean> => {
    try {
      const response = await axios.put(api.mediaLayer(mediaId), {
        backgroundMediaLayer: backgroundId,
      });
      return response.status === 200;
    } catch {
      return false;
    }
  };

  const resetUser = () => {
    setUser(null);
    setPhotos(null);
    setActiveBarcode("");
    setSelectedPhotos([]);
    localStorage.removeItem("barcode");
  };

  const checkIsShouldSurvey = () => {
    if (user?.guestData && !user.guestData.passedSurvey) {
      setIsInterviewing(true);
    }
  };

  const handleSurveyClose = () => {
    setIsInterviewing(false);
  };

  const getUserByBarcode = async (barcode: string) => {
    setActiveBarcode(barcode);
  };

  const saveUser = async (
    ticketBarcodes: string,
    email: string,
    zipCode: string,
    captureNewsYN: boolean
  ) => {
    saveUserMutation.mutate({
      ticketBarcodes,
      email,
      zipCode,
      captureNewsYN,
    });
  };

  const selectPhoto = (id: string, isSelecting: boolean) => {
    setSelectedPhotos((prevList) =>
      isSelecting
        ? [...prevList, id]
        : prevList.filter((listItemId) => listItemId !== id)
    );
  };

  const selectAllPhotos = () => {
    if (photos) {
      setSelectedPhotos(photos.map((photo) => photo.mediaId));
    }
  };

  const selectSinglePhoto = (id: string) => setSelectedPhotos([id]);

  const clearSelectedPhotos = () => setSelectedPhotos([]);

  useEffect(() => {
    const ticketBarcode = searchParams.get("ticketBarcode");
    const isDirectLogin =
      location.pathname === "/login" &&
      ticketBarcode?.length === 18 &&
      !isNaN(+ticketBarcode);

    if (
      !isKiosk &&
      !location.pathname.includes("direct-view") &&
      !isDirectLogin
    ) {
      const barcode = localStorage.getItem("barcode");
      if (barcode?.length === 18) {
        setActiveBarcode(barcode);
        refetchUserData();
      }
    }
  }, [isKiosk, refetchUserData]);

  useEffect(() => {
    if (activeBarcode) {
      refetchUserData();
    }
  }, [activeBarcode]);

  return {
    isLoading: saveUserMutation.isLoading || isUserFetching,
    isLoadingImages,
    getUserByBarcode,
    user,
    saveUser,
    resetUser,
    getUserPhotosByBarcode,
    photos,
    shareImageViaEmail,
    saveSiverScreenBg,
    activeBarcode,
    selectedPhotos,
    selectPhoto,
    selectSinglePhoto,
    selectAllPhotos,
    clearSelectedPhotos,
    isInterviewing,
    checkIsShouldSurvey,
    handleSurveyClose,
    handleSurveySubmit,
  };
};

export default useUserState;
