import { useEffect, useState, useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";

import api from "@api/index";
import { IResponseDto } from "@interfaces/rest/response.dto";
import {
  IActiveThemeDto,
  IApiDataDto,
  IThemeStyle,
  IThemeStyleInstance,
} from "@interfaces/rest/apiData.dto";
import {
  IApiDataConfig,
  IApiDataState,
  IStyleConfig,
} from "@interfaces/types/apiData.type";
import {
  ConfigClientEnum,
  ClientCompanyNameEnum,
  VenueNameEnum,
} from "@interfaces/enums/apiData.enum";
import snStyles from "@theme/sn.json";
import cggStyles from "@theme/cgg.json";

interface IActiveThemeProps {
  url: string;
  client: ConfigClientEnum;
}

const useConfigState = (): IApiDataState => {
  const [config, setConfig] = useState<IApiDataConfig | null>(null);
  const [activeTheme, setActiveTheme] = useState<IActiveThemeDto | null>(null);

  const fetchConfig = async (): Promise<IApiDataDto> => {
    const response = await axios.get(api.config);
    return response.data;
  };

  const { data: configData } = useQuery<IApiDataDto>("getConfig", fetchConfig);

  const fetchActiveTheme = async ({
    url,
    client,
  }: IActiveThemeProps): Promise<IActiveThemeDto | null> => {
    const response = await axios.get<IResponseDto<IActiveThemeDto>>(
      url +
        api.activeTheme(
          client === ConfigClientEnum.sn
            ? ""
            : "accessToken=eV2njHP7yffnM30hB630nqwo3M5rllxUPoQ70zCdvjg==" // fix this!!!!!!!
        )
    );

    return response.data.response;
  };

  const { data: activeThemeData } = useQuery<IActiveThemeDto | null>(
    ["getActiveTheme", configData?.API_URI, config?.client],
    () =>
      fetchActiveTheme({
        url: configData!.API_URI,
        client: config!.client,
      }),
    {
      enabled: !!configData && !!config,
    }
  );

  useEffect(() => {
    if (configData) {
      const params = new URLSearchParams(document.location.search);

      const clientDomain = window.location.hostname.split(
        "-"
      )[0] as ConfigClientEnum;
      const beforeUrl: ConfigClientEnum = Object.keys(
        ConfigClientEnum
      ).includes(clientDomain)
        ? clientDomain
        : ConfigClientEnum.sn;

      const client: ConfigClientEnum =
        window.location.hostname === "localhost"
          ? ConfigClientEnum.sn
          : beforeUrl;

      axios.defaults.baseURL = configData.API_URI;
      setConfig({
        API_URI: configData.API_URI,
        client,
        companyName: ClientCompanyNameEnum[client],
        venueName: VenueNameEnum[client],
        isKiosk: params.get("mode") === "kiosk",
      });
    }
  }, [configData]);

  useEffect(() => {
    if (activeThemeData) {
      setActiveTheme(activeThemeData);

      if (config && !config.isKiosk) {
        document.styleSheets[0].insertRule(
          `:root{--background:url(${activeThemeData.backgroundImage})}`
        );

        const iconLinkDomObj = document.querySelector(
          'link[rel="icon"][type="image/x-icon"]'
        ) as HTMLLinkElement;

        if (iconLinkDomObj && activeThemeData.faviconImageLow) {
          iconLinkDomObj.href = activeThemeData.faviconImageLow;
        }
      }
    }
  }, [activeThemeData, config]);

  useEffect(() => {
    if (config) {
      const themes = {
        sn: snStyles,
        cgg: cggStyles,
      };
      const currentThemeConfig: IStyleConfig = themes[config.client];
      type StyleConfigPropsArray = Array<keyof IStyleConfig>;
      const propsArray: StyleConfigPropsArray = Object.keys(
        currentThemeConfig
      ) as StyleConfigPropsArray;

      propsArray.forEach((keyName: keyof IStyleConfig) => {
        document.styleSheets[0].insertRule(
          `:root{${keyName}:${currentThemeConfig[keyName]}}`
        );
      });
    }
  }, [config?.client]);

  const transformStyleColorsToVars = useCallback((styles: IThemeStyle) => {
    type StyleKeysArray = Array<keyof IThemeStyle>;
    const propsArray: StyleKeysArray = Object.keys(styles) as StyleKeysArray;
    propsArray.forEach((keyName: keyof IThemeStyle) => {
      type StyleInstanceKeyArray = Array<keyof IThemeStyleInstance>;
      const stylnstanceKeyArray: StyleInstanceKeyArray = Object.keys(
        styles[keyName]
      ) as StyleInstanceKeyArray;
      stylnstanceKeyArray.forEach((instanceKey: keyof IThemeStyleInstance) => {
        document.styleSheets[0].insertRule(
          `:root{--${keyName}-${instanceKey}:${styles[keyName][instanceKey]}}`
        );
      });
    });
  }, []);

  useEffect(() => {
    if (activeTheme) {
      transformStyleColorsToVars(activeTheme.style);
    }
  }, [activeTheme]);

  return {
    config,
    activeTheme,
  };
};

export default useConfigState;
