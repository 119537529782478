import React, { FC, PropsWithChildren, createContext } from "react";

import useDeviceMatchState from "./useDeviceMatchState";

const DeviceMatchContext = createContext<any | undefined>(undefined);

const DeviceMatchProvider: FC<PropsWithChildren> = ({ children }) => {
  const deviceMatchState = useDeviceMatchState();

  return (
    <DeviceMatchContext.Provider value={deviceMatchState}>
      {children}
    </DeviceMatchContext.Provider>
  );
};

export { DeviceMatchProvider, DeviceMatchContext };
