import { useState, useMemo } from "react";
import { AlertColor } from "@mui/material";

export interface NotificationState {
  open: boolean;
  message: string;
  type: AlertColor;
}

export const useNotificationState = () => {
  const [notification, setNotification] = useState<NotificationState>({
    open: false,
    message: "",
    type: "success",
  });

  const showNotification = (message: string, type: AlertColor) => {
    setNotification({ open: true, message, type });
  };

  const closeNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const contextValue = useMemo(
    () => ({
      notification,
      showNotification,
      closeNotification,
    }),
    [notification]
  );

  return contextValue;
};
