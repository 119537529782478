import { CSSProperties } from "react";
import { keyframes } from "@emotion/react";

import { NestedCSSProperties } from "@interfaces/types/styles.types";

const rotateplane = keyframes`
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
`;

const load = keyframes`
  0% {
    background-position: 50px 0;
  }
  100% {
    background-position: 0 0;
  }
`;

export const loaderStyles: CSSProperties = {
  width: "30px",
  height: "30px",
  backgroundColor: "primary.main",
  margin: "auto",
  animation: `${rotateplane} 1.2s infinite ease-in-out`,
};

export const loaderWrapperStyles: CSSProperties = {
  position: "relative",
  left: 0,
  top: 0,
  height: "35px",
  zIndex: 999,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const loaderBluredStyles: CSSProperties = {
  backgroundColor: "rgba(255, 255, 255, 0.5)",
};

export const loaderFullHeightStyles: CSSProperties = {
  height: "100%",
};

export const loaderFullWidthStyles: CSSProperties = {
  width: "100%",
};

export const loaderAbsoluteStyles: CSSProperties = {
  position: "absolute",
};

export const loaderFixedStyles: CSSProperties = {
  zIndex: 1000,
  position: "fixed",
  flexDirection: "column",
  padding: "0 25px",
  boxSizing: "border-box",
};

export const loaderContentStyles: CSSProperties = {
  margin: "25px 0",
  padding: "25px",
  width: "100%",
  maxWidth: "600px",
  fontSize: "22px",
  textAlign: "center",
  boxSizing: "border-box",
};

export const loaderCandyStyles: CSSProperties | NestedCSSProperties = {
  display: "block",
  position: "relative",
  width: "100%",
  height: "25px",
  backgroundColor: "primary.main",
  overflow: "hidden",
  "::after": {
    position: "absolute",
    animation: `${load} 1s infinite`,
    content: '""',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundImage:
      "linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)",
    zIndex: 1,
    backgroundSize: "50px 50px",
  },
};

export const loaderTitleStyles: CSSProperties = {
  marginBottom: "20px",
  fontSize: "22px",
  color: "var(--color-bg-primary)",
};
