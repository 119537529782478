import React, { createContext, FC, PropsWithChildren, useMemo } from "react";
import { Snackbar, Alert, AlertColor } from "@mui/material";

import {
  useNotificationState,
  NotificationState,
} from "./useNotificationState";
import { notificationDuration } from "./notification.consts";
import { notificationContentStyles } from "./notification.styles";

interface NotificationContextProps {
  notification: NotificationState;
  showNotification: (message: string, type: AlertColor) => void;
  closeNotification: () => void;
}

export const NotificationContext =
  createContext<NotificationContextProps | null>(null);

const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { notification, showNotification, closeNotification } =
    useNotificationState();

  const contextValue = useMemo(
    () => ({
      notification,
      showNotification,
      closeNotification,
    }),
    [notification, showNotification, closeNotification]
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <Snackbar
        open={notification.open}
        autoHideDuration={notificationDuration}
        onClose={closeNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={closeNotification}
          severity={notification.type}
          sx={notificationContentStyles}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
