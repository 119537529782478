import React, { FC } from "react";
import { ThemeProvider, Theme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import Loader from "@components/loader";
import useTheme from "@hooks/useTheme";

import AxiosClient from "./AxiosClient";
import useConfig from "./providers/configProvider/useConfig";
import AppRoutingModule from "./pages/AppRoutingModule";

const AuthenticationChecker: FC = () => {
  const { config, activeTheme } = useConfig();
  const theme: Theme = useTheme(config?.client);

  return !config || !activeTheme ? (
    <Loader isAbsolute isHeightFull isWidthFull />
  ) : (
    <AxiosClient>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AppRoutingModule />
      </ThemeProvider>
    </AxiosClient>
  );
};

export default AuthenticationChecker;
