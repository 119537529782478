export enum ConfigClientEnum {
  sn = "sn",
  cgg = "cgg",
}

export enum ClientCompanyNameEnum {
  sn = "Space Needle LLC",
  cgg = "Center Art LLC",
}

export enum VenueNameEnum {
  sn = "Space Needle",
  cgg = "Chihuly Garden and Glass",
}

export enum ISalesTerminalTypeId {
  sn = 35,
  cgg = 40,
}

export enum IMemberVenueId {
  sn = 41,
  cgg = 44,
}

export enum ClientDomainEnum {
  sn = "spaceneedle.com",
  cgg = "chihulygardenandglass.com",
}

export enum MediaTypeEnum {
  image = "Image",
  video = "Video",
}
