import { Theme } from "@mui/material/styles";

import { ConfigClientEnum } from "@interfaces/enums/apiData.enum";
import { VenueMap } from "@interfaces/types/config.type";
import cggTheme from "@theme/cggTheme";
import snTheme from "@theme/snTheme";

const venueTheme: VenueMap<Theme> = {
  [ConfigClientEnum.sn]: snTheme,
  [ConfigClientEnum.cgg]: cggTheme,
};

const useTheme = (venue?: ConfigClientEnum): Theme => {
  const defaultTheme: Theme = venueTheme.sn;
  const theme: Theme = venue ? venueTheme[venue] : defaultTheme;
  return theme;
};

export default useTheme;
